import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    creating: false,
    weekly: false,
    fetching: false,
    load: false,
    creating_ingredients: false,
    stats: {
      pageCount: 1
    }
  },

  meals: [],
  meal: {},
  exported: [],
  ingredients: [],
  list: {}
};

export const mealsReducerSlice = createSlice({
  name: 'meals',
  initialState,
  reducers: {
    get_meals: (state) => {
      state.ui.loading = true;
    },
    get_meals_success: (state, { payload }) => {
      const { meals, stats } = payload;
      state.ui.loading = false;
      state.meals = meals;
      state.ui.stats = {
        ...stats
      };
    },
    get_meals_error: (state) => {
      state.ui.loading = false;
    },
    create_meal: (state) => {
      state.ui.creating = true;
    },
    create_meal_success: (state, { payload }) => {
      state.ui.creating = false;
      state.meals.unshift(payload);
    },
    create_meal_error: (state) => {
      state.ui.creating = false;
    },
    get_single_meal: (state) => {
      state.ui.loading = true;
    },
    get_single_meal_success: (state, { payload }) => {
      state.ui.loading = false;
      state.meal = payload;
    },
    get_single_meal_error: (state) => {
      state.ui.loading = false;
    },
    delete_meal: (state) => {
      state.ui.creating = true;
    },
    delete_meal_success: (state) => {
      state.ui.creating = false;
    },
    delete_meal_error: (state) => {
      state.ui.creating = false;
    },
    update_meal: (state) => {
      state.ui.creating = true;
    },
    update_meal_success: (state) => {
      state.ui.creating = false;
    },
    update_meal_error: (state) => {
      state.ui.creating = false;
    },
    get_export: (state) => {
      state.ui.loading = true;
    },
    get_export_success: (state, { payload }) => {
      state.ui.loading = false;
      const { meals } = payload;
      state.exported = meals;
    },
    get_export_error: (state) => {
      state.ui.loading = false;
    },
    get_weekly_preference: (state) => {
      state.ui.weekly = true;
    },
    get_weekly_preference_success: (state) => {
      state.ui.weekly = false;
    },
    get_weekly_preference_error: (state) => {
      state.ui.weekly = false;
    },
    get_ingredients: (state) => {
      state.ui.fetching = true;
    },
    get_ingredients_success: (state, { payload }) => {
      state.ui.fetching = false;
      state.ingredients = payload;
    },
    get_ingredients_error: (state) => {
      state.ui.fetching = false;
    },
    create_ingredients: (state) => {
      state.ui.creating_ingredients = true;
    },
    create_ingredients_success: (state, { payload }) => {
      state.ui.creating_ingredients = false;
      state.ingredients.unshift(payload);
    },
    create_ingredients_error: (state) => {
      state.ui.creating_ingredients = false;
    },
    get_ingredient_list: (state) => {
      state.ui.load = true;
    },
    get_ingredient_list_success: (state, { payload }) => {
      state.ui.load = false;
      state.list = payload;
    },
    get_ingredient_list_error: (state) => {
      state.ui.load = false;
    },
    update_ingredient: (state) => {
      state.ui.creating_ingredients = true;
    },
    update_ingredient_success: (state) => {
      state.ui.creating_ingredients = false;
    },
    update_ingredient_error: (state) => {
      state.ui.creating_ingredients = false;
    },
    reset_ingredient_list: (state) => {
      state.list = {};
    }
  }
});

export const {
  get_meals,
  get_meals_error,
  get_meals_success,
  create_meal,
  create_meal_success,
  create_meal_error,
  get_single_meal,
  get_single_meal_success,
  get_single_meal_error,
  delete_meal,
  delete_meal_success,
  delete_meal_error,
  update_meal,
  update_meal_error,
  update_meal_success,
  get_export,
  get_export_error,
  get_export_success,
  get_weekly_preference,
  get_weekly_preference_error,
  get_weekly_preference_success,
  get_ingredients,
  get_ingredients_success,
  get_ingredients_error,
  create_ingredients,
  create_ingredients_success,
  create_ingredients_error,
  get_ingredient_list,
  get_ingredient_list_success,
  get_ingredient_list_error,
  update_ingredient,
  update_ingredient_error,
  update_ingredient_success,
  reset_ingredient_list
} = mealsReducerSlice.actions;

export default mealsReducerSlice.reducer;
